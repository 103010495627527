<template>
  <div>
    <!-- header -->
    <div class="flex flex-wrap items-center -ml-16 sm:-ml-24 -mt-8">
      <div class="mr-auto ml-16 sm:ml-24 mt-8">
        <p class="text-lg font-medium leading-big">Produkt info</p>
        <p class="mt-4 text-grey-400">Nedenstående viser hvilke data vi har på dit produkt. Kontakt os for at ændre i det</p>
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- title -->
    <table-input-row v-model="productData.title" label="Titel" placeholder="Mystery Box til hund og kat" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- shopify id -->
    <table-input-row v-model="productData.shopify_id" label="Shopify ID" placeholder="gid://shopify/Product/6033573347477" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- payout type -->
    <table-input-row label="Udbetalingstype:" placeholder="fullfilment+14" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- payout target -->
    <table-input-row label="Dato for udbetaling" type="date" placeholder="13.12.2020" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- payout complete -->
    <table-input-row
      v-model="payoutComplete"
      label="Udbetaling afsluttet dato"
      type="date"
      placeholder="15.12.2020"
      readonly
    />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- primary category -->
    <table-input-row
      v-model="productData.data.tags"
      label="Primær kategori"
      placeholder="Hobby & Fritid"
      readonly
    />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- visible from -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16 items-center">
      <p class="font-medium text-grey-400 max-w-sm w-full">Synlig fra</p>
      <div class="mt-4 sm:mt-0 sm:col-span-2">
        <el-input
          v-for="(from, idx) in productData.data.metafields"
          :key="idx"
          v-model="from.value.visibleFrom"
          description="Dato ikke synlig på shop"
          placeholder="27-09-2020"
          readonly
          :class="['max-w-lg sm:max-w-xs', { 'mb-8': idx !== productData.data.metafields.length - 1 }]"
          input-class="shadow-sm rounded"
        />
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- visible to -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16 items-center">
      <p class="font-medium text-grey-400 max-w-sm w-full">Synlig til</p>
      <div class="mt-4 sm:mt-0 sm:col-span-2">
        <el-input
          v-for="(from, idx) in productData.data.metafields"
          :key="idx"
          v-model="from.value.visibleTo"
          description="Dato ikke synlig på shop"
          placeholder="27-09-2020"
          readonly
          :class="['max-w-lg sm:max-w-xs', { 'mb-8': idx !== productData.data.metafields.length - 1 }]"
          input-class="shadow-sm rounded"
        />
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- avisfordele consultant -->
    <table-input-row label="Avisfordele Konsulent" placeholder="Klavs Steenholf" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- expected shipping -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16 items-center">
      <p class="font-medium text-grey-400 max-w-sm w-full">Forventet afsendelse</p>
      <div class="mt-4 sm:mt-0 sm:col-span-2">
        <el-input
          v-for="(from, idx) in productData.data.metafields"
          :key="idx"
          v-model="from.value.expectedShippingDuration"
          placeholder="27-09-2020"
          readonly
          class="max-w-lg shadow-sm sm:max-w-xs"
          input-class="rounded"
        />
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- status -->
    <table-input-row label="Status" placeholder="Afventer godkendelse" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- order items -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Varianter</p>
      <el-order-item :order-items="productData.data.variants" class="mt-4 sm:mt-0 sm:col-span-2">
        <template #quantity="{ item }">{{ item.inventory_quantity }}</template>
        <template #title="{ item }">{{ item.title }}</template>
        <template #info="{ item }">SKU {{ item.sku }} (Stk. pris kr. {{ item.price }})</template>

        <template #default="{ item }">
          <div class="flex flex-col">
            <!-- <p v-for="(meta, idx) in item.metafields" :key="idx">
              {{ getChaining(JSON.parse(meta.value), 'shipping.shippingCompany.companyName', '-') }}
            </p> -->
            <p>{{ getChaining(getMetafield(item), 'shipping.shippingCompany.companyName', '-') }}</p>
          </div>
        </template>
      </el-order-item>
    </div>

    <div style="display:none">
      <hr class="my-20 -mx-24 border-none h-1 bg-hr">

      <div class="mr-auto">
        <p class="text-lg font-medium leading-big">Advertiser Provided Information</p>
        <p class="mt-4 text-grey-400">Use below fields to deliver product information and assets to Avisfordele production team.</p>
      </div>

      <hr class="my-20 -mx-24 border-none h-1 bg-hr">

      <!-- advertiser files -->
      <table-input-row label="Advertiser FIles" type="upload" placeholder="Waiting for approval" />

      <hr class="my-20 -mx-24 border-none h-1 bg-hr">

      <!-- advertiser files -->
      <table-input-row label="Advertiser Images" type="upload" placeholder="Waiting for approval" />

      <hr class="my-20 -mx-24 border-none h-1 bg-hr">

      <!-- advertiser description -->
      <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16 items-center">
        <p class="font-medium text-grey-400 max-w-sm w-full">Expected Shipping Days</p>
        <div class="mt-4 sm:mt-0 sm:col-span-2">
          <el-textarea
            textarea-class="rounded shadow-sm max-w-lg"
            rows="7"
            description="Update this text box with relevant product information for Avisfordele production team."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChaining } from '@/core/helper-functions'

const TableInputRow = () => import('@/components/product/TableInputRow')
const ElInput = () => import('@/components/shared/ELInput')
const ElTextarea = () => import('@/components/shared/ELTextarea')
const ElOrderItem = () => import('@/components/shared/ELOrderItem')

export default {
  name: 'ProductTable',

  components: { TableInputRow, ElInput, ElTextarea, ElOrderItem },

  props: {
    productData: { type: Object, required: true }
  },

  data () {
    return {
      payoutComplete: new Date()
    }
  },

  methods: {
    getMetafield (item) {
      const metafield = item.metafields.find(el => el.namespace === 'dtails_pim')
      if (typeof metafield.value === 'string') metafield.value = JSON.parse(metafield.value)
      return metafield.value
    },

    getChaining
  }
}
</script>

<style lang="scss" scoped>

</style>
